import newIcon from "../assets/icons/plus.svg";
import saveIcon from "../assets/icons/save.svg";
import deleteIcon from "../assets/icons/delete.svg";
import addImageIcon from "../assets/icons/addImage.svg";

const BUTTON_TYPES = {
    newProducts: {
        icon: newIcon,
        title: "NOUVEAU PRODUIT",
        color: "bg-yellow",
    },
    newCategory: {
        icon: newIcon,
        title: "NOUVELLE CATÉGORIE",
        color: "bg-yellow",
    },
    newEditor: {
        icon: newIcon,
        title: "NOUVEL ÉDITEUR",
        color: "bg-yellow",
    },
    newEvent: {
        icon: newIcon,
        title: "NOUVEL ÉVÉNEMENT",
        color: "bg-yellow",
    },
    save: {
        icon: saveIcon,
        title: "SAUVEGARDER",
        color: "bg-blue",
    },
    delete: {
        icon: deleteIcon,
        title: "SUPPRIMER",
        color: "bg-red",
    },
    addImage: {
        icon: addImageIcon,
        title: "CHOISIR UNE IMAGE",
        color: "bg-grey",
    },
};

const ERROR_MESSAGES = {
    required: "Ce champ est obligatoire.",
    integer: "Ce champ doit avoir une valeur entière.",
    positive: "Ce champs doit être plus grand que 0.",
    minPlayer:
        "Ce champ doit être plus grand ou égal au nombre minimum de joueurs.",
    endingHour: "L'heure de fin doit être après l'heure de début.",
    link: "Le lien n'est pas valide.",
    invalidDate: "Impossible de saisir une date avant aujourd'hui.",
};

// const PORT = 8080;

const BASE_URL = `https://api.univers-kids-troyes.com`;

const URLS = {
    category: `${BASE_URL}/categories`,
    editor: `${BASE_URL}/editors`,
    event: `${BASE_URL}/events`,
    product: `${BASE_URL}/products`,
    order: `${BASE_URL}/orders`,
};

export { BUTTON_TYPES, URLS, BASE_URL, ERROR_MESSAGES };
