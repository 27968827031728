import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASE_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const login = async (username, password) => {
        try {
            const response = await axios.post(`${BASE_URL}/auth/signin`, {
                username: username,
                password: password,
            });
            localStorage.setItem("authToken", response.data.accessToken);
            navigate("/");
        } catch (e) {
            if (e.response?.status === 404) {
                setError("username", {
                    type: "bad username",
                    message: "Nom d'utilisateur incorrect.",
                });
            } else if (e.response?.status === 401) {
                setError("password", {
                    type: "bad password",
                    message: "Mot de passe incorrect.",
                });
            } else {
                setError("root.serverError", {
                    type: "internal error",
                    message: e.message,
                });
            }
        }
    };

    const onSubmit = async (data) => {
        await login(data.username, data.password);
    };

    return (
        <>
            <div className="flex flex-col justify-center align-middle py-12 px-32">
                <h1 className="text-purple font-fugaz text-6xl text-center pb-4">
                    <a href="/">
                        UNIVERS <span className="text-yellow">KIDS</span>
                    </a>
                </h1>
                <h3 className="text-center text-2xl">Administrateur</h3>
            </div>
            <div className="flex flex-col gap-4 items-center mx-auto pt-20 w-1/3">
                <TextField
                    fullWidth
                    required
                    label="Nom d'utilisateur"
                    {...register("username", {
                        required: "Le nom d'utilisateur est obligatoire.",
                    })}
                    helperText={errors.username?.message}
                    error={!!errors.username}
                ></TextField>
                <TextField
                    fullWidth
                    type="password"
                    required
                    label="Mot de passe"
                    {...register("password", {
                        required: "Le mot de passe est obligatoire",
                    })}
                    helperText={errors.password?.message}
                    error={!!errors.password}
                ></TextField>
                {errors.serverError?.message && <p>{errors.server.message}</p>}
                <div
                    className="bg-yellow text-white uppercase px-8 py-3 rounded-lg self-end hover:cursor-pointer"
                    onClick={handleSubmit(onSubmit)}
                >
                    Se connecter
                </div>
            </div>
        </>
    );
};

export default LoginPage;
