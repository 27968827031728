import infoimg from "../../assets/icons/info.svg";
import calendarimg from "../../assets/icons/calendar.svg";
import clockimg from "../../assets/icons/clock.svg";
import mapimg from "../../assets/icons/map.svg";
import externalLinkimg from "../../assets/icons/external-link.svg";
import { useState } from "react";
import EventModal from "./eventModal";

const FILTERS = {
    purple: "invert(15%) sepia(96%) saturate(2671%) hue-rotate(313deg) brightness(94%) contrast(98%)",
    yellow: "invert(79%) sepia(85%) saturate(1968%) hue-rotate(325deg) brightness(104%) contrast(103%)",
};

const EVENT_TYPES = ["Événement", "Initiation"];

const DAYS = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."];
const MONTHS = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
];

const parseHours = (item) => {
    const startHour = item.beginning?.split(":")[0];
    const startMin = item.beginning?.split(":")[1];
    const endHour = item.ending?.split(":")[0];
    const endMin = item.ending?.split(":")[1];

    const parsedStartHour =
        startHour?.length === 1 ? `0${startHour}` : startHour;
    const parsedStartMin = startMin?.length === 1 ? `0${startMin}` : startMin;
    const parsedEndHour = endHour?.length === 1 ? `0${endHour}` : endHour;
    const parsedEndMin = endMin?.length === 1 ? `0${endMin}` : endMin;

    const parsedString = `${[parsedStartHour, parsedStartMin].join(":")} - ${[
        parsedEndHour,
        parsedEndMin,
    ].join(":")}`;
    return parsedString;
};

const EventCard = (props) => {
    const { item, handleUpdate, handleDelete } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const iconSize = 20;
    const formatDate = new Date(item.date);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const onUpdate = async (data) => {
        await handleUpdate(item, data);
    };

    const onDelete = async () => {
        await handleDelete(item);
    };

    return (
        <>
            {item && (
                <div
                    className="shadow-theme w-72 flex flex-col gap-5 p-5 rounded-lg hover:cursor-pointer z-0 hover:scale-105 duration-100"
                    onClick={handleOpen}
                >
                    <h3 className="text-xl">{item.title}</h3>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-row gap-5">
                            <img
                                src={infoimg}
                                alt="icône informations"
                                height={iconSize}
                                width={iconSize}
                                style={{
                                    filter:
                                        item.type === 0
                                            ? FILTERS.purple
                                            : FILTERS.yellow,
                                }}
                            />
                            <p className="text-sm font-light">
                                {item.description}
                            </p>
                        </div>
                        <div className="flex flex-row gap-5">
                            <img
                                style={{
                                    filter:
                                        item.type === 0
                                            ? FILTERS.purple
                                            : FILTERS.yellow,
                                }}
                                src={calendarimg}
                                alt="icône calendrier"
                                height={iconSize}
                                width={iconSize}
                            />
                            <p className="text-sm font-light">
                                {DAYS[formatDate.getDay()]}{" "}
                                {formatDate.getDate()}{" "}
                                {MONTHS[formatDate.getMonth()]}
                            </p>
                        </div>
                        <div className="flex flex-row gap-5">
                            <img
                                style={{
                                    filter:
                                        item.type === 0
                                            ? FILTERS.purple
                                            : FILTERS.yellow,
                                }}
                                src={clockimg}
                                alt="icône horloge"
                                height={iconSize}
                                width={iconSize}
                            />
                            <p className="text-sm font-light">
                                {parseHours(item)}
                            </p>
                        </div>
                        <div className="flex flex-row gap-5">
                            <img
                                style={{
                                    filter:
                                        item.type === 0
                                            ? FILTERS.purple
                                            : FILTERS.yellow,
                                }}
                                src={mapimg}
                                alt="icône localisation"
                                height={iconSize}
                                width={iconSize}
                            />
                            <p className="text-sm font-light">
                                {item.location}
                            </p>
                        </div>
                        {item.link && (
                            <div className="flex flex-row gap-5">
                                <img
                                    style={{
                                        filter:
                                            item.type === 0
                                                ? FILTERS.purple
                                                : FILTERS.yellow,
                                    }}
                                    src={externalLinkimg}
                                    alt="icône lien externe"
                                    height={iconSize}
                                    width={iconSize}
                                />
                                <a
                                    href={item.link}
                                    target="_blank"
                                    className="text-sm font-light underline"
                                >
                                    {"Inscription sur Discord"}
                                </a>
                            </div>
                        )}
                        {item.link2 && (
                            <div className="flex flex-row gap-5">
                                <img
                                    style={{
                                        filter:
                                            item.type === 0
                                                ? FILTERS.purple
                                                : FILTERS.yellow,
                                    }}
                                    src={externalLinkimg}
                                    alt="icône lien externe"
                                    height={iconSize}
                                    width={iconSize}
                                />
                                <a
                                    href={item.link2}
                                    target="_blank"
                                    className="text-sm font-light underline"
                                >
                                    {"Inscription sur Facebook"}
                                </a>
                            </div>
                        )}
                    </div>
                    <div
                        className={`border-2 px-8 py-2 rounded-3xl w-fit mt-auto ${
                            item.type === 0
                                ? "border-purple text-purple"
                                : "border-yellow text-yellow"
                        }`}
                    >
                        {EVENT_TYPES[item.type]}
                    </div>
                </div>
            )}
            <EventModal
                open={modalOpen}
                handleClose={handleClose}
                title={`modification de ${item?.title}`}
                submitActions={[onUpdate, onDelete]}
                item={item}
            />
        </>
    );
};

export default EventCard;
