import { useEffect, useState } from "react";
import { BUTTON_TYPES, URLS } from "../utils/constants";
import TileItem from "../components/tile/tileItem";
import { Skeleton } from "@mui/material";
import PageTitle from "../components/utils/pageTitle";
import Notification from "../components/utils/notification";
import IconButton from "../components/utils/button";
import SmallModal from "../components/tile/smallModal";
import { useDataContext } from "../data/DataContext";

const Editors = () => {
    const { state, fetchData, createData, updateData, deleteData } =
        useDataContext();
    const [modalOpen, setModalOpen] = useState(false);
    const [notification, setNotification] = useState(true);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleCloseNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setNotification(false);
    };

    const handleCreate = async (data) => {
        await createData(URLS.editor, data);
        setNotification(true);
    };

    const handleUpdate = async (item, data) => {
        await updateData(URLS.editor, item, data);
        setNotification(true);
    };

    const handleDelete = async (item) => {
        await deleteData(URLS.editor, item);
        setNotification(true);
    };

    useEffect(() => {
        fetchData(URLS.editor);
    }, []);

    return (
        <div>
            <div className="flex flex-row py-12 justify-between">
                {state.loading ? (
                    <Skeleton variant="rounded" height={50} width={220} />
                ) : (
                    <PageTitle title={`${state.count} éditeur(s)`} />
                )}
                <IconButton
                    type={BUTTON_TYPES.newEditor}
                    onClick={handleOpen}
                />
            </div>
            <div className="flex flex-row flex-wrap gap-5">
                {state.loading ? (
                    <>
                        <Skeleton variant="rounded" height={50} width={220} />
                        <Skeleton variant="rounded" height={50} width={220} />
                        <Skeleton variant="rounded" height={50} width={220} />
                    </>
                ) : (
                    state.data.map((item, i) => (
                        <TileItem
                            key={i}
                            item={item}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                        />
                    ))
                )}
            </div>
            {(state.status === 201 || state.status === 204) && (
                <Notification
                    message={
                        state.status === 201
                            ? "Opération réalisée avec succès !"
                            : state.status === 204 &&
                              "Élément supprimé avec succès !"
                    }
                    isOpen={notification}
                    onClose={handleCloseNotification}
                />
            )}
            <SmallModal
                open={modalOpen}
                handleClose={handleClose}
                submitAction={handleCreate}
                title="nouvel éditeur"
                type="save"
            />
        </div>
    );
};

export default Editors;
