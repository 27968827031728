const initialState = {
    loading: false,
    data: [],
    error: null,
    status: 304,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_INIT":
        case "CREATE_INIT":
        case "DELETE_INIT":
        case "UPDATE_INIT":
            return {
                ...state,
                loading: true,
                error: null,
                status: 102,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.data,
                count: action.payload.count,
                status: 200,
            };
        case "CREATE_SUCCESS":
            return {
                ...state,
                loading: false,
                error: null,
                data: [action.payload, ...state.data],
                count: state.count + 1,
                status: 201,
            };
        case "DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                error: null,
                data: Object.prototype.hasOwnProperty.call(action.payload, "id")
                    ? state.data.filter((item) => item.id !== action.payload.id)
                    : state.data.filter(
                          (item) => item.ref !== action.payload.ref
                      ),
                count: state.count - 1,
                status: 204,
            };
        case "UPDATE_SUCCESS":
            return {
                ...state,
                loading: false,
                error: null,
                data: Object.prototype.hasOwnProperty.call(action.payload, "id")
                    ? state.data.map((item) =>
                          item.id === action.payload.id ? action.payload : item
                      )
                    : state.data.map((item) =>
                          item.ref === action.payload.ref
                              ? action.payload
                              : item
                      ),
                status: 201,
            };
        case "FETCH_FAILURE":
        case "CREATE_FAILURE":
        case "DELETE_FAILURE":
        case "UPDATE_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                status: action.status,
            };
        default:
            throw new Error("Une erreur inconnue s'est produite");
    }
};

export { reducer, initialState };
