import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./routes/errorPage";
import Stock from "./routes/stock";
import Categories from "./routes/categories";
import Editors from "./routes/editors";
import Orders from "./routes/orders";
import Events from "./routes/events";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { frFR } from "@mui/x-date-pickers/locales";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import "dayjs/locale/fr";
import DataProvider from "./components/DataProvider";
import LoginPage from "./routes/loginPage";
import ProtectedRoute from "./components/ProtectedRoute";

const router = createBrowserRouter([
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/",
        element: <ProtectedRoute element={<Root />} />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "stock",
                element: <ProtectedRoute element={<Stock />} />,
            },
            {
                path: "categories",
                element: <ProtectedRoute element={<Categories />} />,
            },
            {
                path: "editors",
                element: <ProtectedRoute element={<Editors />} />,
            },
            {
                path: "orders",
                element: <ProtectedRoute element={<Orders />} />,
            },
            {
                path: "events",
                element: <ProtectedRoute element={<Events />} />,
            },
        ],
    },
]);

const theme = createTheme(frFR);

ReactDOM.createRoot(document.getElementById("root")).render(
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DataProvider>
                <RouterProvider router={router} />
            </DataProvider>
        </LocalizationProvider>
    </ThemeProvider>
);
