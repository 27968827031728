const IconButton = (props) => {
    const { type, onClick } = props;
    return (
        <div
            className={
                type.color +
                " flex flex-row align-middle gap-5 px-5 py-4 rounded-lg w-fit hover:cursor-pointer hover:scale-105 duration-100"
            }
            onClick={onClick}
            {...props}
        >
            <img src={type.icon} height={18} width={18} className="invert" />
            <p className="text-white text-s font-bold">{type.title}</p>
        </div>
    );
};

export default IconButton;
