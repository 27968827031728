import { useState } from "react";
import { BASE_URL } from "../../utils/constants";
import ProductModal from "./productModal";

const ProductCard = (props) => {
    const { item, onUpdate, onDelete } = props;
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = async (data) => {
        await onUpdate(item, data);
    };

    const handleDelete = async () => {
        await onDelete(item);
    };

    return (
        <>
            <div
                className="flex flex-col p-3 gap-3 shadow-theme rounded-lg w-72 h-72 items-center hover:cursor-pointer hover:scale-105 duration-100"
                onClick={handleOpen}
            >
                <img
                    src={`${BASE_URL}/${item.image}`}
                    alt="image du produit"
                    className="h-1/2"
                />
                <div className="gap-0 px-3 w-full items-start overflow-hidden text-ellipsis">
                    <p className="font-light line-clamp-1">Réf: {item.ref}</p>
                    <p className="font-light line-clamp-1">Nom: {item.name}</p>
                    <p className="font-light">
                        P.U. (€) : {Number.parseFloat(item.price).toFixed(2)}
                    </p>
                    <p className="font-light">
                        Stock en magasin: {item.stockStore}
                    </p>
                    <p className="font-light">
                        Stock en ligne: {item.stockOnline}
                    </p>
                </div>
            </div>
            {open && (
                <ProductModal
                    title={`modification du produit ${item.name}`}
                    item={item}
                    open={open}
                    handleClose={handleClose}
                    submitActions={[handleUpdate, handleDelete]}
                />
            )}
        </>
    );
};

export default ProductCard;
