import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import closeIcon from "../../assets/icons/cross.svg";
import IconButton from "../utils/button";
import { BUTTON_TYPES } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";

const SmallModal = (props) => {
    const { title, open, handleClose, submitAction, type } = props;
    const {
        register,
        handleSubmit,
        resetField,
        setFocus,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            name: title.split(" ").splice(2, 3).join(" "),
        },
    });

    useEffect(() => {
        setFocus("name");
        resetField("name", {
            defaultValue: title.split(" ").splice(2, 3).join(" "),
        });
        clearErrors();
    }, [setFocus, resetField, open]);

    const onSubmit = useCallback(async (data) => {
        try {
            await submitAction(data);
            handleClose();
        } catch (error) {
            setError("name", {
                type: "400",
                message: error.message,
            });
        }
    }, []);

    return (
        <Backdrop open={open} sx={{ zIndex: 3 }}>
            <div className="bg-white shadow-theme flex flex-col gap-5 p-5 rounded-lg">
                <div className="flex flex-row gap-64">
                    <h3 className="uppercase text-3xl">{title}</h3>
                    <img
                        src={closeIcon}
                        alt=""
                        onClick={handleClose}
                        className="hover:cursor-pointer"
                        width={20}
                        height={20}
                    />
                </div>
                <div className="flex flex-col items-end p-3 gap-12">
                    {type === "save" ? (
                        <div className="flex flex-col gap-0 w-full">
                            <p className="text-black text-lg pl-1 pb-1">Nom*</p>
                            <TextField
                                className="w-full"
                                variant="outlined"
                                placeholder="Nom"
                                onKeyDown={(e) => {
                                    clearErrors();
                                    if (e.key === "Enter") {
                                        if (e.target.value === "") {
                                            setError("name", {
                                                message:
                                                    "Ce champ ne peut être vide.",
                                            });
                                        } else {
                                            e.preventDefault();
                                            handleSubmit(
                                                onSubmit({
                                                    name: e.target.value,
                                                })
                                            );
                                        }
                                    }
                                }}
                                {...register("name", {
                                    required: "Ce champ ne peut être vide.",
                                })}
                            />
                            {errors.name && (
                                <p className="text-red text-xs">
                                    {errors.name.message}
                                </p>
                            )}
                        </div>
                    ) : (
                        <p className="w-full">
                            {"Êtes-vous sûr de vouloir supprimer l'élément ?"}
                        </p>
                    )}
                    <IconButton
                        type={
                            type === "save"
                                ? BUTTON_TYPES.save
                                : BUTTON_TYPES.delete
                        }
                        onClick={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </Backdrop>
    );
};

export default SmallModal;
