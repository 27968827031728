import { useLocation } from "react-router-dom";
import Navbar from "../components/utils/navbar";

const Root = () => {
    return (
        <div className="flex flex-col justify-center align-middle py-12 px-32">
            <h1 className="text-purple font-fugaz text-6xl text-center pb-12">
                <a href="/">
                    UNIVERS <span className="text-yellow">KIDS</span>
                </a>
            </h1>
            <Navbar />
            {useLocation().pathname === "/" && (
                <div className="flex flex-col w-full gap-16 justify-center pt-24">
                    <h2 className="text-center text-2xl">
                        Bienvenue sur la page administrateur de{" "}
                        <span className="text-purple font-bold">UNIVERS</span>{" "}
                        <span className="text-yellow font-bold">KIDS</span> !
                    </h2>
                    <p className="text-center text-lg px-32">
                        Ici, vous pouvez consulter et gérer vos stock, les
                        catégories de produits que vous vendez, les éditeurs
                        dont vous revendez les produits. Vous pouvez également
                        consulter les commandes passées et créer des événements
                        !
                    </p>
                </div>
            )}
        </div>
    );
};

export default Root;
