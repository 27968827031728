import { Skeleton } from "@mui/material";
import IconButton from "../components/utils/button";
import { BUTTON_TYPES, URLS } from "../utils/constants";
import { useEffect, useState } from "react";
import PageTitle from "../components/utils/pageTitle";
import EventCard from "../components/event/eventCard";
import EventModal from "../components/event/eventModal";
import Notification from "../components/utils/notification";
import { useDataContext } from "../data/DataContext";

const Events = () => {
    const { state, fetchData, createData, updateData, deleteData } =
        useDataContext();
    const [modalOpen, setModalOpen] = useState(false);
    const [notification, setNotification] = useState(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleCloseNotification = () => {
        setNotification(false);
    };

    const handleCreate = async (data) => {
        await createData(URLS.event, data);
        setNotification(true);
    };

    const handleUpdate = async (item, data) => {
        await updateData(URLS.event, item, data);
        fetchData(URLS.event);
        setNotification(true);
    };

    const handleDelete = async (item) => {
        await deleteData(URLS.event, item);
        setNotification(true);
    };

    useEffect(() => {
        fetchData(URLS.event);
    }, []);

    return (
        <div>
            <div className="flex flex-row py-12 justify-between">
                {state.loading ? (
                    <Skeleton variant="rounded" height={50} width={220} />
                ) : (
                    <PageTitle title={`${state.count} événement(s)`} />
                )}
                <IconButton type={BUTTON_TYPES.newEvent} onClick={handleOpen} />
            </div>
            <div className="flex flex-row flex-wrap gap-5">
                {state.error && <p>{state.error}</p>}
                {state.loading ? (
                    <>
                        <Skeleton variant="rounded" height={330} width={288} />
                        <Skeleton variant="rounded" height={330} width={288} />
                        <Skeleton variant="rounded" height={330} width={288} />
                    </>
                ) : (
                    state.data.map((item, i) => (
                        <EventCard
                            key={i}
                            item={item}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                        />
                    ))
                )}
            </div>
            {modalOpen && (
                <EventModal
                    open={modalOpen}
                    handleClose={handleClose}
                    title="nouvel événement"
                    submitActions={[handleCreate]}
                />
            )}
            {(state.status === 201 || state.status === 204) && (
                <Notification
                    message={
                        state.status === 201
                            ? "Opération réalisée avec succès !"
                            : state.status === 204 &&
                              "Élément supprimé avec succès !"
                    }
                    isOpen={notification}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
    );
};

export default Events;
