import { useReducer } from "react";
import { DataContext } from "../data/DataContext";
import { reducer, initialState } from "../reducers/reducer";
import axios from "axios";

const DataProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchData = async (url) => {
        dispatch({ type: "FETCH_INIT" });
        try {
            const response = await axios.get(url);
            const result = response.data;
            dispatch({ type: "FETCH_SUCCESS", payload: result });
        } catch (error) {
            if (error.response) {
                dispatch({
                    type: "FETCH_FAILURE",
                    error: error.response.data.message,
                    status: error.response.status,
                });
            }
        }
    };

    const fetchDataWithParams = async (url, options) => {
        dispatch({ type: "FETCH_INIT" });
        try {
            const response = await axios.post(url, options);
            const result = response.data;
            dispatch({ type: "FETCH_SUCCESS", payload: result });
        } catch (error) {
            if (error.response) {
                dispatch({
                    type: "FETCH_FAILURE",
                    error: error.response.data.message,
                });
            }
        }
    };

    const createData = async (url, data) => {
        dispatch({ type: "CREATE_INIT" });
        try {
            const response = await axios.post(`${url}/new`, data, {
                headers: {
                    "x-access-token": localStorage.getItem("authToken"),
                },
            });
            const result = response.data.data;
            dispatch({
                type: "CREATE_SUCCESS",
                payload: result,
            });
        } catch (error) {
            if (error.response) {
                dispatch({
                    type: "CREATE_FAILURE",
                    error: error.response.data.details.message,
                });
                throw new Error(error.response.data.details.message);
            }
        }
    };

    const updateData = async (url, item, data) => {
        const param = Object.prototype.hasOwnProperty.call(item, "id")
            ? item.id
            : item.ref.replaceAll(" ", "%20");
        try {
            const response = await axios.put(`${url}/${param}`, data, {
                headers: {
                    "x-access-token": localStorage.getItem("authToken"),
                },
            });
            const result = response.data.data;
            dispatch({
                type: "UPDATE_SUCCESS",
                payload: result,
            });
        } catch (error) {
            console.log(error);
            if (error.response) {
                dispatch({
                    type: "UPDATE_FAILURE",
                    error: error.response.data.details.message,
                });
                throw new Error(error.response.data.details.message);
            }
        }
    };

    const deleteData = async (url, item) => {
        dispatch({ type: "DELETE_INIT" });
        const param = Object.prototype.hasOwnProperty.call(item, "id")
            ? item.id
            : item.ref;
        try {
            await axios.delete(`${url}/${param}`, {
                headers: {
                    "x-access-token": localStorage.getItem("authToken"),
                },
            });
            dispatch({
                type: "DELETE_SUCCESS",
                payload: item,
            });
        } catch (error) {
            if (error.response) {
                dispatch({ type: "DELETE_FAILURE", error: error.response });
            }
        }
    };

    return (
        <DataContext.Provider
            value={{
                state,
                dispatch,
                fetchData,
                fetchDataWithParams,
                createData,
                updateData,
                deleteData,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataProvider;
