import { TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import ProductModal from "./productModal";
import warningIcon from "../../assets/icons/warning.svg";

const ProductRow = (props) => {
    const { item, onUpdate, onDelete, index } = props;
    const [open, setOpen] = useState(false);
    const color = index % 2 === 0 ? "bg-purple" : "bg-white";
    const textColor = index % 2 === 0 ? "text-white" : "text-black";

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = async (data) => {
        await onUpdate(item, data);
    };

    const handleDelete = async () => {
        await onDelete(item);
    };

    return (
        <>
            <TableRow
                key={index}
                className={`${color} hover:cursor-pointer hover:scale-105 duration-100`}
                onClick={handleOpen}
            >
                <TableCell align="center">
                    <p className={`${textColor}`}>{item.ref}</p>
                </TableCell>
                <TableCell align="center">
                    <p className={`${textColor}`}>{item.name}</p>
                </TableCell>
                <TableCell align="center">
                    <p className={`${textColor}`}>{item.price}</p>
                </TableCell>
                <TableCell align="center">
                    <div className="flex flex-row items-start justify-center gap-1">
                        <p className={`${textColor}`}>{item.stockStore}</p>
                        {item.stockStore <= 3 && (
                            <img
                                src={warningIcon}
                                width={18}
                                height={18}
                                className={index % 2 === 0 && "invert"}
                            />
                        )}
                    </div>
                </TableCell>
                <TableCell align="center">
                    <div className="flex flex-row items-start justify-center gap-1">
                        <p className={`${textColor}`}>{item.stockOnline}</p>
                        {item.stockOnline <= 3 && (
                            <img
                                src={warningIcon}
                                width={18}
                                height={18}
                                className={index % 2 === 0 && "invert"}
                            />
                        )}
                    </div>
                </TableCell>
            </TableRow>
            {open && (
                <ProductModal
                    title={`modification du produit ${item.name}`}
                    item={item}
                    open={open}
                    handleClose={handleClose}
                    submitActions={[handleUpdate, handleDelete]}
                />
            )}
        </>
    );
};

export default ProductRow;
