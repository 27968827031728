/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import IconButton from "../components/utils/button";
import PageTitle from "../components/utils/pageTitle";
import Notification from "../components/utils/notification";
import { BUTTON_TYPES, URLS } from "../utils/constants";
import {
    FormControlLabel,
    InputAdornment,
    Pagination,
    Skeleton,
    styled,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import ProductModal from "../components/product/productModal";
import ProductCard from "../components/product/productCard";

import searchIcon from "../assets/icons/search.svg";
import { useDataContext } from "../data/DataContext";
import ProductRow from "../components/product/ProductRow";

const CustomSwitch = styled(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
        margin: 1,
        paddinig: 0,
        transform: "translateX(-10px) translateY(-7px)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(22px) translateY(-7px)",
            "& .MuiSwitch-thumb:before": {
                ndRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "40%",
                backgroundImage: "url(/icons/row.svg)",
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "lightgrey",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: 28,
        height: 28,
        background:
            "linear-gradient(90deg, rgb(239, 31, 92) 0%, rgb(184, 22, 104) 64%, rgb(180, 22, 102) 100%)",
        "&::before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "30%",
            backgroundImage: "url(/icons/grid.svg)",
            filter: "invert(1)",
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        borderRadius: 20 / 2,
        backgroundColor: "lightgrey",
    },
}));

const Stock = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [view, setView] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [notification, setNotification] = useState(false);
    const { state, fetchDataWithParams, createData, updateData, deleteData } =
        useDataContext();

    const handlePage = (event, value) => {
        setPage(value);
    };

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleCloseNotification = () => {
        setNotification(false);
    };

    const handleCreate = async (data) => {
        await createData(URLS.product, data);
        setNotification(true);
    };

    const handleUpdate = async (item, data) => {
        await updateData(URLS.product, item, data);
        await fetchDataWithParams(URLS.product, { offset: (page - 1) * 12 });
        setNotification(true);
    };

    const handleDelete = async (item) => {
        await deleteData(URLS.product, item);
        setNotification(true);
    };

    const handleSearch = async (page) => {
        if (search === "") {
            await fetchDataWithParams(URLS.product, {});
        } else {
            const whereClause = { name: search };
            await fetchDataWithParams(URLS.product, {
                whereClause: whereClause,
                offset: (page - 1) * 12,
            });
        }
    };

    useEffect(() => {
        search === ""
            ? fetchDataWithParams(URLS.product, {
                  offset: (page - 1) * 12,
              })
            : handleSearch(page);
    }, [page]);

    return (
        <div className="flex flex-col gap-5 justify-center items-center">
            <div className="flex flex-row py-12 justify-between w-full">
                {state.loading ? (
                    <Skeleton variant="rounded" height={50} width={220} />
                ) : (
                    <div className="flex flex-row gap-16 items-baseline">
                        <PageTitle
                            title={`${state.count} produit(s) en stock`}
                        />
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={view}
                                    onChange={() =>
                                        setView((prevState) => !prevState)
                                    }
                                />
                            }
                        />
                    </div>
                )}
                <div className="flex flex-row gap-16 items-center">
                    <TextField
                        className="z-0"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={async (e) => {
                            if (e.key === "Enter") {
                                await handleSearch();
                            }
                        }}
                        label="Rechercher un produit"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img
                                        src={searchIcon}
                                        width={24}
                                        height={24}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton
                        type={BUTTON_TYPES.newProducts}
                        onClick={handleOpen}
                    />
                </div>
            </div>
            <div className="flex flex-row flex-wrap gap-5 justify-center w-full">
                {state.loading ? (
                    <>
                        <Skeleton variant="rounded" height={287} width={287} />
                        <Skeleton variant="rounded" height={287} width={287} />
                        <Skeleton variant="rounded" height={287} width={287} />
                        <Skeleton variant="rounded" height={287} width={287} />
                    </>
                ) : state.data.length === 0 ? (
                    <p>{"Aucun élément n'a été trouvé."}</p>
                ) : view ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <p className="font-bold">Réf. produit</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">Nom</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">P.U. (€)</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">Stock magasin</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">Stock en ligne</p>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.data.map((item, i) => (
                                <ProductRow
                                    key={i}
                                    item={item}
                                    index={i}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                />
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    state.data.map((item, i) => (
                        <ProductCard
                            key={i}
                            item={item}
                            onUpdate={handleUpdate}
                            onDelete={handleDelete}
                        />
                    ))
                )}
            </div>
            {state.count > 0 && (
                <Pagination
                    page={page}
                    onChange={handlePage}
                    count={Math.floor((state.count + 11) / 12)}
                />
            )}
            {modalOpen && (
                <ProductModal
                    title="Nouveau produit"
                    open={modalOpen}
                    handleClose={handleClose}
                    submitActions={[handleCreate]}
                />
            )}
            {notification && (
                <Notification
                    message="Opération réalisée avec succès !"
                    isOpen={notification}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
    );
};

export default Stock;
