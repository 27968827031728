import { Outlet, Link } from "react-router-dom";
import stockIcon from "../../assets/icons/stock.svg";
import categoriesIcon from "../../assets/icons/categories.svg";
import editorsIcon from "../../assets/icons/editors.svg";
import ordersIcon from "../../assets/icons/orders.svg";
import eventsIcon from "../../assets/icons/events.svg";
import { useEffect, useState } from "react";

const NavLink = (props) => {
    const { icon, title, url, active } = props;
    return (
        <Link
            key={title}
            to={url}
            className="flex flex-row p-2.5 gap-2.5 align-middle"
            style={{
                filter:
                    active &&
                    "invert(15%) sepia(96%) saturate(2671%) hue-rotate(313deg) brightness(94%) contrast(98%)",
            }}
        >
            <img src={icon} width={28} height={28} />
            {title}
        </Link>
    );
};

const Navbar = () => {
    const data = [
        {
            title: "Stock",
            url: "/stock",
            icon: stockIcon,
        },
        {
            title: "Catégories",
            url: "/categories",
            icon: categoriesIcon,
        },
        {
            title: "Éditeurs",
            url: "/editors",
            icon: editorsIcon,
        },
        {
            title: "Commandes",
            url: "/orders",
            icon: ordersIcon,
        },
        {
            title: "Événements",
            url: "/events",
            icon: eventsIcon,
        },
    ];

    const [activeLink, setActiveLink] = useState(window.location.pathname);

    const handleChangeActive = () => {
        setActiveLink(window.location.pathname);
    };

    useEffect(() => handleChangeActive(), []);

    return (
        <>
            <nav className="flex justify-center">
                <div className="flex flex-row gap-4 justify-center p-5 rounded-xl bg-white shadow-theme">
                    {data.map((link, i) => (
                        <div key={i} onClick={handleChangeActive}>
                            <NavLink
                                title={link.title}
                                icon={link.icon}
                                url={link.url}
                                active={link.url === activeLink}
                            />
                        </div>
                    ))}
                </div>
            </nav>
            <div>
                <Outlet />
            </div>
        </>
    );
};

export default Navbar;
