import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
    const token = localStorage.getItem("authToken");
    if (!token) return false;

    const payload = JSON.parse(atob(token.split(".")[1]));

    const now = Math.floor(Date.now() / 1000);
    return payload.exp > now;
};

const ProtectedRoute = ({ element }) => {
    if (isAuthenticated() === false) {
        return <Navigate to="/login" />;
    }

    return element;
};

export default ProtectedRoute;
