import editIcon from "../../assets/icons/edit.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import { useState } from "react";
import SmallModal from "./smallModal";

const TileItem = (props) => {
    const { item, handleUpdate, handleDelete } = props;
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleOpenUpdateModal = () => {
        setUpdateModalOpen(true);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleCloseUpdateModal = () => {
        setUpdateModalOpen(false);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const onUpdate = async (data) => {
        await handleUpdate(item, data);
    };

    const onDelete = async () => {
        await handleDelete(item);
    };

    return (
        <>
            <div className="flex flex-row bg-grey gap-12 py-4 pl-6 pr-3 rounded-lg">
                <p className="font-bold text-base">{item.name}</p>
                <div className="flex flex-row gap-4">
                    <img
                        src={editIcon}
                        alt="icône crayon pour modifier"
                        onClick={handleOpenUpdateModal}
                        width={20}
                        height={20}
                        style={{
                            filter: "invert(50%) sepia(51%) saturate(594%) hue-rotate(143deg) brightness(99%) contrast(94%)",
                        }}
                        className="hover:cursor-pointer hover:scale-125 duration-150"
                    />
                    <img
                        src={deleteIcon}
                        alt="icône poublelle pour supprimer"
                        onClick={handleOpenDeleteModal}
                        width={20}
                        height={20}
                        style={{
                            filter: "brightness(0) saturate(100%) invert(29%) sepia(88%) saturate(5276%) hue-rotate(349deg) brightness(100%) contrast(88%)",
                        }}
                        className="hover:cursor-pointer hover:scale-125 duration-150"
                    />
                </div>
            </div>
            <SmallModal
                title={`suppression de ${item.name}`}
                type="delete"
                open={deleteModalOpen}
                handleClose={handleCloseDeleteModal}
                submitAction={onDelete}
            />
            <SmallModal
                title={`modification de ${item.name}`}
                type="save"
                open={updateModalOpen}
                handleClose={handleCloseUpdateModal}
                submitAction={onUpdate}
            />
        </>
    );
};

export default TileItem;
