import { Snackbar } from "@mui/material";

const Notification = ({ message, isOpen, onClose }) => {
    return (
        <Snackbar
            key={Math.random()}
            message={message}
            open={isOpen}
            onClose={onClose}
            autoHideDuration={3500}
            ContentProps={{
                sx: {
                    background:
                        "linear-gradient(90deg, rgb(239, 31, 92) 0%, rgb(184, 22, 104) 64%, rgb(180, 22, 102) 100%)",
                },
            }}
        />
    );
};

export default Notification;
